import React, {Component} from 'react';
import {Table, Button, Spinner, Modal, Alert} from "react-bootstrap";
import {http, endpoints} from '../../../config/http';
import {Formik} from "formik";
import {TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import clsx from "clsx";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Swal from "sweetalert2";
import store, { persistor } from "../../../store/store";


class Questions extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            questions: [],
            show:false,
            isSubmitting: false,
            paddingRight: "2.5rem",
            open: false,
            openCorrect:false,
            cat: "",
            status: null,
            success:false,
            isDeleting:false,
            options:[],
            subject:null,
            showView: false,
            currentItem: null
        };

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShowView = this.handleShowView.bind(this);
        this.handleCloseView = this.handleCloseView.bind(this);
        this.enableLoadling = this.enableLoadling.bind(this);
        this.disableLoadling = this.disableLoadling.bind(this);
        this.handleOpenSelect = this.handleOpenSelect.bind(this);
        this.handleCloseSelect = this.handleCloseSelect.bind(this);
        this.handleChangeCat= this.handleChangeCat.bind(this);
        this.handleOpenCorrect = this.handleOpenCorrect.bind(this);
        this.handleCloseCorrect = this.handleCloseCorrect.bind(this);

    }
    componentDidMount(){


        this.setupCourse();
    }

    handleClose() {
        this.setState({ show: false,success: false });
    }

    handleShow() {
        this.setState({ show: true });
    }
    handleChangeCat(event) {
        this.setState({ cat: event.target.value });
    }

    handleShowView(item) {
        this.setState({ showView: true, currentItem: item  });
    }
    handleCloseView() {
        this.setState({ showView: false, currentItem: null });
    }


    enableLoadling() {
        this.setState({ isSubmitting: true,paddingRight: "3.5rem",success: false });
    }
    disableLoadling() {
        this.setState({ isSubmitting: false,paddingRight: "2.5rem" });
    }
    addQuestion(question,optionA, optionB,optionC,optionD,subject, correct){
        var options = [];
        var optionAObject = new Object();
        optionAObject.name = optionA;
        optionAObject.isAnswer = correct === "A"? 1 : 0;
        options.push(optionAObject);
        var optionBObject = new Object();
        optionBObject.name = optionB;
        optionBObject.isAnswer = correct === "B"? 1 : 0;
        options.push(optionBObject);
        var optionCObject = new Object();
        optionCObject.name = optionC;
        optionCObject.isAnswer = correct === "C"? 1 : 0;
        options.push(optionCObject);
        var optionDObject = new Object();
        optionDObject.name = optionD;
        optionDObject.isAnswer = correct === "D"? 1 : 0;
        options.push(optionDObject);

        this.setState({
            options: options
        });


        return http.post(endpoints.ADD_QUESTION, {
            question,
            subject,
            options,
        });
    }

    handleCloseSelect() {
        this.setState({ open: false });

    }


    handleDelete = (id) => {
        Swal.fire({
            title:
                "Are you sure you want to delete question "
                ,
            text:
                "You won't be able to revert this this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isDeleting: true
                });
                http
                    .delete(endpoints.DELETE_QUESTION.replace(":id",id)
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "Question deleted successfully "
                        });
                        this.setState({
                            isDeleting: false
                        });
                        this.loadQuestions(this.state.subject.id);

                    })
                    .catch(error => {
                        this.setState({
                            isDeleting: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };

    handleOpenSelect() {
        this.setState({ open: true });
    }
    handleCloseCorrect() {
        this.setState({ openCorrect: false });

    }

    handleOpenCorrect() {
        this.setState({ openCorrect: true });
    }


    setupCourse(){
        const { subject } = this.props.location.state;
        this.setState({
            subject
        });
        this.loadQuestions(subject.id);
    }

    loadQuestions(id){
        this.setState({
            isLoading: true
        });
        http
            .get(endpoints.GET_QUESTION_BY_SUBJECT.replace(':id', id)
            )
            .then(res => {
                this.setState({
                    isLoading :false,
                    questions: res.data
                })
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }

    getRowsData = function() {
        const {questions} = this.state;
        return questions.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td><Button variant="primary"  disabled={this.state.isDeleting} onClick={() => this.handleShowView(item)} >View</Button><Button variant="danger" onClick={() => this.handleDelete(item.id)} disabled={this.state.isDeleting}>Delete</Button></td>
                </tr>
            );
        });
    };

    getRowsCurrentData = function() {
        const {currentItem} = this.state;
        return currentItem.options.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>
                    <td>{item.isAnswer === 1 && 'Correct Answer'}</td>
                </tr>
            );
        });
    };



    render() {
        const {isLoading, success, subject, showView, currentItem} = this.state;
        return (
            <div className="container">
                <div>
                    {subject &&
                    <div>
                        {subject.title}

                    </div>
                    }
                    <Button variant="primary" size="lg" active  style={{float:'right'}} onClick={this.handleShow}>
                        Add New Question
                    </Button>
                </div>
                {isLoading &&
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                }

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Question</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getRowsData()}
                    </tbody>
                </Table>
                {subject &&
                <Modal show={this.state.show} onHide={this.handleClose} backdrop="static">
                    <Modal.Header closeButton>

                        <Modal.Title>Add New Question for {subject.code}({subject.title})</Modal.Title>

                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={{
                                question: "",
                                optionA: "",
                                optionB: "",
                                optionC: "",
                                optionD: "",
                                subject: subject.id,
                                correct: "",
                            }}
                            validate={values => {
                                const errors = {};

                                if (!values.question) {
                                    // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                                    errors.question = "Question body is required"
                                }

                                if (!values.optionA) {
                                    errors.optionA = "Option A is required"
                                }

                                if (!values.optionB) {
                                    errors.optionB = "Option B is required"
                                }

                                if (!values.optionC) {
                                    errors.optionC = "Option C is required"
                                }
                                if (!values.optionD) {
                                    errors.optionD = "Option D is required"
                                }
                                if (!values.correct) {
                                    errors.correct = "Correct answer is required"
                                }
                                return errors;
                            }}
                            onSubmit={(values, {setStatus, setSubmitting}) => {
                                this.enableLoadling();
                                setSubmitting(true);
                                setStatus(null);
                                setTimeout(() => {
                                    this.addQuestion(values.question, values.optionA, values.optionB, values.optionC, values.optionD, subject.id, values.correct)
                                        .then(response => {
                                            // alert(accessToken);
                                            this.setState({paddingRight: "2.5rem", success: true});
                                            this.loadQuestions(subject.id);
                                            setSubmitting(false);
                                            values.question = "";
                                            values.optionA = "";
                                            values.optionB = "";
                                            values.optionC = "";
                                            values.optionD = "";
                                            values.correct = "";
                                            // props.login(response.data.token, response.data.user);
                                        })
                                        .catch(e => {
                                            console.log(e);
                                            setSubmitting(false);
                                            this.setState({paddingRight: "2.5rem", success: false});
                                            setStatus(e);
                                        });
                                }, 1000);
                            }}
                        >
                            {({
                                  values,
                                  status,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting
                              }) => (
                                <form
                                    noValidate={true}
                                    autoComplete="off"
                                    className="kt-form"
                                    onSubmit={handleSubmit}
                                >
                                    {status &&
                                    <div role="alert" className="alert alert-danger">
                                        <div className="alert-text">{status}</div>
                                    </div>
                                    }
                                    {success &&
                                    <Alert variant='success'>
                                        Question has been created
                                    </Alert>
                                    }

                                    <div className="form-group">
                                        <TextField
                                            type="text"
                                            label="Question"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="question"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.question}
                                            helperText={touched.question && errors.question}
                                            error={Boolean(touched.question && errors.question)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            type="text"
                                            label="Option A"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="optionA"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.optionA}
                                            helperText={touched.optionA && errors.optionA}
                                            error={Boolean(touched.optionA && errors.optionA)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            type="text"
                                            label="Option B"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="optionB"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.optionB}
                                            helperText={touched.optionB && errors.optionB}
                                            error={Boolean(touched.optionB && errors.optionB)}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <TextField
                                            type="text"
                                            label="Option C"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="optionC"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.optionC}
                                            helperText={touched.optionC && errors.optionC}
                                            error={Boolean(touched.optionC && errors.optionC)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <TextField
                                            type="text"
                                            label="Option D"
                                            margin="normal"
                                            className="kt-width-full"
                                            name="optionD"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.optionD}
                                            helperText={touched.optionD && errors.optionD}
                                            error={Boolean(touched.optionD && errors.optionD)}
                                        />
                                    </div>

                                    <div className="form-group">

                                        <FormControl style={{minWidth: 120, marginLeft: 50}}>
                                            <InputLabel htmlFor="demo-controlled-open-select">Correct
                                                Answer</InputLabel>
                                            <Select
                                                open={this.state.openCorrect}
                                                onClose={this.handleCloseCorrect}
                                                onOpen={this.handleOpenCorrect}
                                                value={values.correct}
                                                onChange={handleChange}
                                                error={Boolean(touched.correct && errors.correct)}
                                                inputProps={{
                                                    name: 'correct',
                                                    id: 'demo-controlled-open-select',
                                                }}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                <MenuItem value="A">A</MenuItem>
                                                <MenuItem value="B">B</MenuItem>
                                                <MenuItem value="C">C</MenuItem>
                                                <MenuItem value="D">D</MenuItem>
                                            </Select>
                                        </FormControl>

                                    </div>

                                    <div className="kt-login__actions">
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleClose}>
                                                Close
                                            </Button>
                                            <Button variant="primary" type="submit" disabled={isSubmitting}>
                                                Save Changes {isSubmitting &&
                                            <Spinner animation="border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </Spinner>
                                            }
                                            </Button>
                                        </Modal.Footer>

                                    </div>
                                </form>
                            )}
                        </Formik>

                    </Modal.Body>

                </Modal>
                }
                {currentItem &&

                <Modal show={currentItem && showView} onHide={this.handleCloseView} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Question: {currentItem.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Options</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.getRowsCurrentData()}
                            </tbody>
                        </Table>


                    </Modal.Body>

                </Modal>
                }
            </div>
        );
    }
}

export default Questions;
