import React, {Component} from 'react';
import {http, endpoints} from '../../../config/http';
import {Button, Modal, Spinner, Table} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactToPrint from 'react-to-print';
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import './Sample.css';
import Swal from "sweetalert2";
var ReactDOMServer = require('react-dom/server');
var HtmlToReactParser = require('html-to-react').Parser;

class TheoryResult extends Component {
    constructor() {
        super();
        this.state = {
            student : null,
            sessions: [],
            isLoading: false,
            currentItem: null,
            showView: false,
            isDeleting:false,
        }
        this.handleShowView = this.handleShowView.bind(this);
        this.handleCloseView = this.handleCloseView.bind(this);
    }

    componentDidMount(){
        this.setupStudent();

    }
    setupStudent(){
        const {student} = this.props.location.state;
        this.setState({
            student
        });
        this.loadStudentExam(student.id);
        document.title = "RESULT - "+student.name;
    }
    handleShowView(item) {
        this.setState({ showView: true, currentItem: item  });
    }
    handleCloseView() {
        this.setState({ showView: false, currentItem: null });
    }
    loadStudentExam(id){
        this.setState({
            isLoading: true
        });
        http
            .get(endpoints.EXAM_RESULT_STUDENT.replace(':id',id)
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                    sessions: res.data
                })
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    handleDelete = (id) => {
        const {student} = this.props.location.state;
        Swal.fire({
            title:
                "Are you sure you want to delete exam"
            ,
            text:
                "Deleting exam will lead to loosing student exam for this course",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isDeleting: true
                });
                http
                    .delete(endpoints.EXAM_SESSION_DELETE.replace(":id",id)
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "Exam deleted successfully "
                        });
                        this.setState({
                            isDeleting: false
                        });
                        this.loadStudentExam(student.id);

                    })
                    .catch(error => {
                        this.setState({
                            isDeleting: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };
    getRowsData = function() {
        const {sessions} = this.state;
        return sessions.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.exam.subject.title} - {item.exam.subject.code}  </td>

                    <td> <Button variant="primary"  onClick={() => this.handleShowView(item)}>View</Button>
                        <Button variant="danger"    style={{marginLeft: 10}} onClick={()=> this.handleDelete(item.id)} disabled={this.state.isDeleting}>Delete</Button>

                    </td>

                </tr>
            );
        });
    };



    render() {
        const {student,sessions, isLoading, currentItem, showView} = this.state;
        return (
            <div className="container">
                <div>
                    {student &&
                    <div>
                        <h2>Results for {student.name}</h2>

                    </div>
                    }
                </div>
                {isLoading &&
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                }

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Course</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getRowsData()}
                    </tbody>
                </Table>

                {currentItem &&

                <Modal show={currentItem && showView} onHide={this.handleCloseView} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Exams Details for {currentItem.exam.subject.code}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ReactToPrint
                            trigger={() => {
                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                // to the root node of the returned component as it will be overwritten.
                                return <a href="#">Print this out!</a>;
                            }}
                            content={() => this.componentRef}
                        />

                    <ComponentToPrint ref={el => (this.componentRef = el)} currentItem={currentItem} student={student}/>


                    </Modal.Body>

                </Modal>
                }
            </div>
        );
    }
}
class ComponentToPrint extends React.Component {
    getRowsCurrentData = function() {
        const {currentItem} = this.props;
        return currentItem.answers.map((item, index) => {

            var htmlInput = item.question;
            var htmlToReactParser = new HtmlToReactParser();
            var reactElement = htmlToReactParser.parse(htmlInput);
            var reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);

            var htmlInput2 = item.answer?item.answer: '' ;
            var htmlToReactParser2 = new HtmlToReactParser();
            var reactElement2 = htmlToReactParser.parse(htmlInput2);
            var reactHtml2 = ReactDOMServer.renderToStaticMarkup(reactElement);
            return (
                <tr key={index}>
                    {item.answer &&
                    <td style={{padding:40}}>
                        <h5>Question {index+1}</h5>
                        {reactElement}

                        <h5 style={{marginTop:10}}>Answer </h5>


                        <p>   {reactElement2}</p>


                    </td>
                    }

                </tr>
            );
        });
    };
    render() {
        const {currentItem, student} = this.props;
        return (


            <Table striped bordered hover>

                <thead>
                <tr>
                    <th style={{textAlign:'center'}}>
                        <img src="/media/logos/es.png" className="logoImage" style={{width:'100%'}}/>
                        <h3>ANSWER SHEET</h3>
                        <h6 style={{marginTop: 10}}>Instruction: {currentItem.exam.instruction}</h6>
                    </th>
                </tr>
                <tr>

                        <td style={{padding:40}}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <p><strong>Course Title: </strong>{currentItem.exam.subject.title}</p>
                                    <p><strong>Course Code: </strong> {currentItem.exam.subject.code}</p>
                                    <p><strong>Duration: </strong> {currentItem.exam.duration} minutes</p>
                                    <p><strong>Exam Date:</strong> {moment(currentItem.exam.start_time).format('MMMM Do YYYY')} </p>
                                    <p><strong>Time:</strong> {moment(currentItem.exam.start_time).format('hh:mm:ss a')} </p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p><strong>Name: </strong>{student.name}</p>
                                    <p><strong>Matric: </strong>{student.matric}</p>
                                    <p><strong>Department: </strong>{student.course}</p>
                                    <p><strong>Level: </strong>{student.level}00</p>
                                    <p><strong>Semeter: </strong>Second</p>
                                </Grid>


                            </Grid>

                        </td>


                </tr>

                </thead>
                <tbody className='watermark'>
                {this.getRowsCurrentData()}
                </tbody>
            </Table>
        );
    }
}

export default TheoryResult;
