import React, {Component} from 'react';
import {Table, Button, Spinner, Modal, Alert} from "react-bootstrap";
import {http, endpoints} from '../../../config/http';
import {Formik} from "formik";
import {TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import clsx from "clsx";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Swal from "sweetalert2";
import store, {persistor} from "../../../store/store";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

class TheorySubjects extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            students: [],
            show:false,
            isSubmitting: false,
            paddingRight: "2.5rem",
            open: false,
            cat: "",
            status: null,
            success:false,
            currentStudent: null,
            showSectionC:false,
            showSectionB:false,
            sectionCscore: null,
            sectionBscore: null,
            isSubmittingScore: false,
            isDeleting: false,
            course:null,
            level: null,
        };


    }

    handleDelete = (id) => {
        const { course, level } = this.props.location.state;
        Swal.fire({
            title:
                "Are you sure you want to delete exam "
            ,
            text:
                "Deleting exam will lead to loosing all student exams for this course",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isDeleting: true
                });
                http
                    .delete(endpoints.EXAM_DELETE.replace(":id",id)
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "Exam deleted successfully "
                        });
                        this.setState({
                            isDeleting: false
                        });
                        this.loadSubjects(course.id,level);

                    })
                    .catch(error => {
                        this.setState({
                            isDeleting: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };



    componentDidMount(){

        this.setupCourse();

    }
    setupCourse(){
        const { course, level } = this.props.location.state;
        this.setState({
            course, level
        });
        this.loadSubjects(course.id, level);
    }


    enableLoadling() {
        this.setState({ isSubmitting: true,paddingRight: "3.5rem",success: false });
    }
    disableLoadling() {
        this.setState({ isSubmitting: false,paddingRight: "2.5rem" });
    }



    loadSubjects(dept, level){
        this.setState({
            isLoading: true
        });
        http
            .get(endpoints.GET_SUBJECTS.replace(':dept', dept).replace(':level', level)
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                    students: res.data
                })
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }

    getRowsData = function() {
        const {students} = this.state;
        return students.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.code} </td>


                    <td>{item.semester === 1? 'First': 'Second'}</td>
                    <td>{item.exam ? moment(item.exam.start_time).format('MMMM Do YYYY hh:mm:ss a'): 'No exam yet'} </td>



                    <td>
                        {item.exam ?     <div>

                            <Link to={{
                                pathname: '/view-exam',
                                state: {
                                    subject: item,
                                }

                            }}
                                  className=""><Button variant="success"        disabled={this.state.isDeleting}>Questions</Button>
                            </Link>
                    <Button variant="danger"    style={{marginLeft: 10}} onClick={()=> this.handleDelete(item.exam.id)} disabled={this.state.isDeleting}>Delete</Button>


                        </div>  :                            <Link to={{
                            pathname: '/create-exam',
                            state: {
                                subject: item,
                                course: this.state.course,
                                level: this.state.level,
                            }
                        }}
                                                                   className=""><Button variant="primary"  disabled={this.state.isDeleting}>Create Exam</Button>
                        </Link>}



                    </td>

                </tr>
            );
        });
    };

    render() {
        const {isLoading,course, level} = this.state;
        return (
            <div className="container">
                <div>
                    {course &&
                    <div>
                        <h2>{course.name} - {level}00 level courses</h2>

                    </div>
                    }
                </div>
                {isLoading &&
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                }

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Semester</th>
                        <th>Exam Time</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getRowsData()}
                    </tbody>
                </Table>


            </div>
        );
    }
}

export default TheorySubjects;
