import {persistReducer} from "redux-persist";
import {actionTypes} from "./actionTypes";
import storage from "redux-persist/lib/storage";
import { REHYDRATE } from 'redux-persist/lib/constants';

let initialQuiz = {
    quiz: {
        config: {
            'allowBack': true,
            'allowReview': true,
            'autoMove': false,  // if true, it will move to next question automatically when answered.
            'duration': 5,  // indicates the time in which quiz needs to be completed. 0 means unlimited.
            'pageSize': 1,
            'requiredAll': false,  // indicates if you must answer all the questions before submitting.
            'richText': false,
            'shuffleQuestions': false,
            'shuffleOptions': false,
            'showClock': true,
            'showPager': true,
            'theme': 'none'
        },
        questions: []
    },
    mode: 'quiz',
    score: null,
    userId: null,
    BStartTime: null,
    AStartTime: null,
    CStartTime: null,
    pager: {
        index: 0,
        size: 1,
        count: 1
    }
}

// export default (state = { ...initialQuiz }, action) => {
//     switch (action.type) {
//         case ActionTypes.PagerUpdate:
//             return {
//                 ...state, pager: action.payload, mode: 'quiz'
//             }
//         case ActionTypes.QuizLoad:
//             return {
//                 ...state, quiz: action.payload
//             }
//         case ActionTypes.QuizSubmit:
//             console.log('quiz.js Submitted.');
//             return {
//                 ...state, mode: action.payload
//             }
//         case ActionTypes.QuizAnswer:
//             console.log('quiz.js reducer Quiz Answer called.');
//             return {
//                 ...state, quiz: action.payload
//             }
//         default:
//             return state;
//     }
// };


// const initialExamState = {
//     isFetching: false,
//     data: [],
//     message: null,
//     failed: false,
// };


export const exams = persistReducer(
    { storage, key: "ongoing-exam", whitelist: ["quiz",'pager', 'score','mode', 'userId','BStartTime', 'AStartTime', 'CStartTime'] },
    (state = initialQuiz, action) => {


        switch (action.type) {
            case actionTypes.PagerUpdate:
                return {
                    ...state, pager: action.payload, mode: 'quiz'
                }
            case actionTypes.QuizLoad:
                return {
                    ...state, quiz: action.payload
                }
            case actionTypes.QuizSubmit:
                console.log('quiz.js Submitted.');
                return {
                    ...state, mode: action.payload
                }
            case actionTypes.SetScore:
                console.log('set score Submitted.');
                return {
                    ...state, score: action.payload.score, userId: action.payload.userId
                }
            case actionTypes.BStartTimeUpdate:
               // console.log('set score Submitted.');
                return {
                    ...state, BStartTime: action.payload
                }
            case actionTypes.CStartTimeUpdate:
                // console.log('set score Submitted.');
                return {
                    ...state, CStartTime: action.payload
                }
            case actionTypes.AStartTimeUpdate:
                // console.log('set score Submitted.');
                return {
                    ...state, AStartTime: action.payload
                }
            case actionTypes.QuizAnswer:
                console.log('quiz.js reducer Quiz Answer called.');
                return {
                    ...state, quiz: action.payload
                }
            default:
                return state;
        }
    }
);




