

export default {
    header: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                alignment: "left",
                page: "dashboard",
                translate: "MENU.DASHBOARD"
            },
            {
                title: "Students",
                root: true,
                alignment: "left",
                page: "courses",
                translate: "MENU.DASHBOARD"
            },
            {
                title: "Questions",
                root: true,
                alignment: "left",
                page: "questionsc",
                translate: "MENU.DASHBOARD"
            },
            {
                title: "Theory Questions",
                root: true,
                alignment: "left",
                page: "theory-questions",
                translate: "MENU.DASHBOARD"
            },
        ]
    },
    aside: {
        self: {},
        items: [
            {
                title: "Dashboard",
                root: true,
                icon: "flaticon2-architecture-and-city",
                page: "dashboard",
                translate: "MENU.DASHBOARD",
                bullet: "dot"
            },
            {
                title: "Students",
                root: true,
                icon: "flaticon2-expand",
                page: "courses"
            },
            {
                title: "Questions",
                root: true,
                icon: "flaticon2-expand",
                page: "questionsc"
            },
            {
                title: "Theory Questions",
                root: true,
                icon: "flaticon2-expand",
                page: "theory-questions"
            },



            // {
            //   root: true,
            //   title: "Documentation",
            //   bullet: "dot",
            //   icon: "flaticon2-file-1",
            //   submenu: [
            //     { title: "Quick Start", page: "docs/quick-start" },
            //     { title: "Overview", page: "docs/overview" },
            //     { title: "Deployment", page: "docs/deployment" },
            //     { title: "Internationalization", page: "docs/i18n" },
            //     { title: "Mock Backend", page: "docs/mock-backend" },
            //     { title: "Create a Page", page: "docs/create-a-page" }
            //   ]
            // },
        ]
    }
};
