

export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        alignment: "left",
        page: "dashboard",
        translate: "MENU.DASHBOARD"
      },
      // {
      //   title: "Take Objective Exam",
      //   root: true,
      //   alignment: "left",
      //   page: "take-exam",
      //   translate: "MENU.DASHBOARD"
      // },
      {
        title: "Take Exam",
        root: true,
        alignment: "left",
        page: "take-theory-exam",
        translate: "MENU.DASHBOARD"
      },

    ]
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot"
      },
      // {
      //   title: "Take Objective Exam",
      //   root: true,
      //   icon: "flaticon2-expand",
      //   page: "take-exam"
      // },
      {
        title: "Take Exam",
        root: true,
        icon: "flaticon2-expand",
        page: "take-theory-exam"
      },


      // {
      //   root: true,
      //   title: "Documentation",
      //   bullet: "dot",
      //   icon: "flaticon2-file-1",
      //   submenu: [
      //     { title: "Quick Start", page: "docs/quick-start" },
      //     { title: "Overview", page: "docs/overview" },
      //     { title: "Deployment", page: "docs/deployment" },
      //     { title: "Internationalization", page: "docs/i18n" },
      //     { title: "Mock Backend", page: "docs/mock-backend" },
      //     { title: "Create a Page", page: "docs/create-a-page" }
      //   ]
      // },

    ]
  }
};
