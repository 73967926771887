import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch ,BrowserRouter} from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import Students from "./admin/Students";
import Questions from "./admin/Questions";
import Courses from "./admin/Courses";
import Subjects from "./admin/Subjects";
import TheorySubjects from "./admin/TheorySubjects";
import TheoryQuestions from "./admin/TheoryQuestion";
import QuestionCourse from "./admin/QuestionCourse";
import ExamHome from "./student/ExamHome";
import ExamTheoryHome from "./student/ExamTheoryHome";
import ExamTheorySession from "./student/TheoryExamSession";
import CreateExam from "./admin/CreateExam";
import ViewQuestions from "./admin/ViewQuestions";
import Results from "./admin/TheoryResult";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";
import {shallowEqual, useSelector} from "react-redux";
import * as routerHelpers from "../../router/RouterHelpers";

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

export default function HomePage() {
  // useEffect(() => {
  //   console.log('Home page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect
  const { isAdmin, menuConfig, userLastLocation } = useSelector(
      ({ auth, urls, builder: { menuConfig } }) => ({
        menuConfig,
        isAdmin: auth.user.role === 'admin',
        userLastLocation: routerHelpers.getLastLocation()
      }),
      shallowEqual
  );

  return (
    <Suspense fallback={<LayoutSplashScreen />}>

      <Switch>

        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {isAdmin &&
        <Route path="/students" component={Students}/>
        }
        {isAdmin &&
        <Route path="/courses" component={Courses}/>
        }
          {isAdmin &&
          <Route path="/questionsc" component={QuestionCourse}/>
          }
          {isAdmin &&
          <Route path="/subjects" component={Subjects}/>
          }
          {isAdmin &&
          <Route path="/theory-subjects" component={TheorySubjects}/>
          }
          {isAdmin &&
          <Route path="/theory-questions" component={TheoryQuestions}/>
          }
          {isAdmin &&
          <Route path="/create-exam" component={CreateExam}/>
          }
          {isAdmin &&
          <Route path="/view-exam" component={ViewQuestions}/>
          }
          {isAdmin &&
          <Route path="/results" component={Results}/>
          }
        {/*{isAdmin &&*/}
        {/*<Route path="/students/:course/:level" exact component={Students}/>*/}
        {/*}*/}
        <Route path="/take-exam" component={ExamHome} />
          <Route path="/take-theory-exam" component={ExamTheoryHome} />
          <Route path="/theory-exam" component={ExamTheorySession} />

        <Route path="/questions" component={Questions}/>

        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />

      </Switch>

    </Suspense>
  );
}
