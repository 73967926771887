import React, {Component} from 'react';
import {Alert, Button, Modal, Spinner, Table} from "react-bootstrap";
import {Formik} from "formik";
import {TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {http, endpoints} from '../../../config/http';
import {Link} from "react-router-dom";

class QuestionCourse extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            courses: []
        }
    }

    componentDidMount(){

        this.loadCourses();
    }

    loadCourses(){
        this.setState({
            isLoading: true
        });
        http
            .get(endpoints.COURSES
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                    courses: res.data
                })
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    getRowsData = function() {
        const {courses} = this.state;
        return courses.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.name}</td>

                    <td>  <Link to={{
                        pathname: '/subjects',
                        state: {
                            course: item,
                            level: 1
                        }
                    }}
                                className="">
                        100
                    </Link>
                    </td>
                    <td>  <Link to={{
                        pathname: '/subjects',
                        state: {
                            course: item,
                            level: 2
                        }
                    }}
                                className="">
                        200
                    </Link>
                    </td>
                    <td>  <Link to={{
                        pathname: '/subjects',
                        state: {
                            course: item,
                            level: 3
                        }
                    }}
                                className="">
                        300
                    </Link></td>
                </tr>
            );
        });
    };

    render() {
        const {isLoading} = this.state;
        return (
            <div className="container">
                <div>

                </div>
                {isLoading &&
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                }

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th></th>
                        <th></th>
                        <th></th>

                    </tr>
                    </thead>
                    <tbody>
                    {this.getRowsData()}
                    </tbody>
                </Table>


            </div>
        );
    }
}

export default QuestionCourse;
