
export const ActionTypes = {
    QuizLoad: 'QuizLoad',
    QuizAnswer: 'QuizAnswer',
    QuizSubmit: 'QuizSubmit',
    PagerUpdate: 'PagerUpdate',
    SetScore: 'SetScore',
    BStartTimeUpdate:  "BStartTime",
    AStartTimeUpdate:  "AStartTime",
    CStartTimeUpdate:  "CStartTime",
}