import React, {Component} from 'react';
import {http, endpoints} from '../../../config/http';
import {Button, Modal, Spinner, Table} from "react-bootstrap";
import moment from "moment";
import {Link} from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import './Sample.css';
import Swal from "sweetalert2";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html';
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
var ReactDOMServer = require('react-dom/server');
var HtmlToReactParser = require('html-to-react').Parser;


class ViewQuestions extends Component {
    constructor() {
        super();
        this.state = {
            subject : null,
            exam: null,
            isLoading: false,
            show: false,
            openCorrect: false,
            value: new Date(),
            start_time: '',
            duration: null,
            isDeleting: false,
            questions : [],
            instruction: '',
            mode: 'exam'
        }
        this.handleShowView = this.handleShowView.bind(this);
        this.handleCloseView = this.handleCloseView.bind(this);
        this.handleOpenCorrect = this.handleOpenCorrect.bind(this);
        this.handleCloseCorrect = this.handleCloseCorrect.bind(this);
    }

    componentDidMount(){
        this.setupQuestion();
    }
    setupQuestion(){
        const {subject} = this.props.location.state;
        this.setState({
            subject
        });
        this.loadQuestions(subject.id);
    }
    loadQuestions(id){
        this.setState({
            isLoading: true
        });
        http
            .get(endpoints.EXAM_QUESTIONS_THEORY.replace(':id', id)
            )
            .then(res => {
                console.log(res.data);
                this.setState({
                    isLoading :false,
                    exam: res.data,
                    duration: res.data.duration,
                    value: new Date(res.data.start_time
                    ),
                    instruction: res.data.instruction,
                    start_time:res.data.start_time,
                })
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    handleCloseCorrect() {
        this.setState({ openCorrect: false });

    }

    handleOpenCorrect() {
        this.setState({ openCorrect: true });
    }
    handleShowView() {
        this.setState({ show: true  });
    }
    handleCloseView() {
        this.setState({ show: false });
    }
    onChange = value => {
        this.setState({
            value ,
            start_time: moment(value).format('YYYY-MM-DDTHH:mm:ss.sssZ')
        });
    }
    handleSubmit = () => {
        // const {course, subject, level} = this.props.location.state;
        const {duration, start_time, subject, instruction} = this.state;

        this.setState({
            isLoading: true
        });
        http
            .put(endpoints.UPDATE_EXAM.replace(':id', subject.id),{
                duration,
                start_time,
                instruction
                }
            )
            .then(res => {
                console.log(res.data);
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    icon: "success",
                    title: "Time updated"
                });
                this.handleCloseView();
                this.loadQuestions(subject.id);
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    handleSelectChange = duration => {this.setState({
        duration
    })}

    handleDelete = (id) => {
        Swal.fire({
            title:
                "Are you sure you want to delete question "
            ,
            text:
                "You won't be able to revert this this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isDeleting: true
                });
                http
                    .delete(endpoints.DELETE_THEORY_QUESTION.replace(":id",id)
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "Question deleted successfully "
                        });
                        this.setState({
                            isDeleting: false
                        });
                        this.loadQuestions(this.state.subject.id);

                    })
                    .catch(error => {
                        this.setState({
                            isDeleting: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };

    getRowsData = function() {
        const {exam} = this.state;

        return exam.questions.map((item, index) => {
            var htmlInput = item.question;
            var htmlToReactParser = new HtmlToReactParser();
            var reactElement = htmlToReactParser.parse(htmlInput);
            var reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{reactElement} </td>
                    <td>
                      <Button variant="danger"  onClick={() => this.handleDelete(item.id)} disabled={this.state.isDeleting}>Delete</Button>



                    </td>

                </tr>
            );
        });
    };
    createUI(){
        return this.state.questions.map((el, i) =>
            <div key={i} style={{marginTop:15}}>
                {/*<input type="text" value={el.question||''} onChange={this.handleChange.bind(this, i)} />*/}

                <h3>Question {this.state.exam.questions.length + i+1}</h3>
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <Editor
                            ref = {'editor'+i}
                            editorState={el.editor}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="demoWrap"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.handleChange.bind(this, i)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="danger" onClick={this.removeClick.bind(this, i)}>Remove</Button>
                        {/*<input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/>*/}
                    </Grid>

                </Grid>
            </div>
        )
    }

    handleChange(i, event) {
        let questions = [...this.state.questions];
        questions[i].editor = event;
        questions[i].question = stateToHTML(event.getCurrentContent());
        this.setState({ questions });
    }

    addClick(){
        this.setState(prevState => ({ questions: [...prevState.questions, {question: ''}]}))
    }

    removeClick(i){
        let questions = [...this.state.questions];
        questions.splice(i,1);
        this.setState({ questions });
    }
    handleSubmitQuestion = () => {
        const { subject } = this.props.location.state;
        const {questions} = this.state;

        Swal.fire({
            title:
                "Are you add questions "
            ,
            text:
                "You won't be able to revert this this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Add",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isLoading: true
                });
                http
                    .post(endpoints.ADD_THEORY_QUESTIONS,{questions,subject: subject.id
                        }
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "questions added"
                        });
                        this.setState({
                            isLoading: false,
                            questions: []
                        });
                        this.loadQuestions(subject.id);
                    })
                    .catch(error => {
                        this.setState({
                            isLoading: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };

    render() {
        const {isLoading,subject, exam, show, value, duration, questions, mode, instruction} = this.state;
        return (

            <div className="container">
                <div>
                    {subject &&
                    <div>
                        <h2>{subject.title} - Exam questions</h2>
                    </div>
                    }
                </div>

                <div>
                    {exam &&
                    <div>
                        <p>Instruction : {exam.instruction} </p>
                        <p>Date : {moment(exam.start_time).format('MMMM Do YYYY')} </p>
                        <p>Start Time : {moment(exam.start_time).format('hh:mm:ss a')} </p>
                        <p>End Time : {moment(exam.end_time).format('hh:mm:ss a')}  </p>
                        <p>Duration : {exam.duration} minutes </p>
                        <Button variant="primary" onClick={()=>this.handleShowView()}>Change Time or Instruction</Button>
                    </div>
                    }
                </div>
                {isLoading &&
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                }

                <Table striped bordered hover style={{marginTop:15}}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Question</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    {exam &&
                    <tbody>

                    {this.getRowsData()}
                    </tbody>
                    }
                </Table>
                <Modal show={show} onHide={this.handleCloseView} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Exam Time</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <div className="Sample mTop">
                            <header>
                                <h2>Select Exam date and time</h2>
                            </header>
                            <div className="Sample__container">
                                <main className="Sample__container__content">
                                    <DateTimePicker
                                        amPmAriaLabel="Select AM/PM"
                                        calendarAriaLabel="Toggle calendar"
                                        clearAriaLabel="Clear value"
                                        dayAriaLabel="Day"
                                        hourAriaLabel="Hour"
                                        maxDetail="second"
                                        closeWidgets={true}
                                        minuteAriaLabel="Minute"
                                        monthAriaLabel="Month"
                                        nativeInputAriaLabel="Date and time"
                                        onChange={this.onChange}
                                        secondAriaLabel="Second"
                                        value={value}
                                        minDate={new Date()}
                                        yearAriaLabel="Year"
                                    />
                                </main>
                            </div>

                            <div className="form-group mTop">

                                <FormControl style={{minWidth: 120}}>
                                    <InputLabel htmlFor="demo-controlled-open-select">Exam Duration</InputLabel>
                                    <Select
                                        open={this.state.openCorrect}
                                        onClose={this.handleCloseCorrect}
                                        onOpen={this.handleOpenCorrect}
                                        value={duration}
                                        onChange={(duration)=> this.handleSelectChange(duration.target.value)}
                                        inputProps={{
                                            name: 'correct',
                                            id: 'demo-controlled-open-select',
                                        }}
                                    >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value="15">15 minutes</MenuItem>
                                        <MenuItem value="30">30 minutes</MenuItem>
                                        <MenuItem value="45">45 minutes</MenuItem>
                                        <MenuItem value="60">60 minutes</MenuItem>
                                        <MenuItem value="90">90 minutes</MenuItem>
                                        <MenuItem value="120">120 minutes</MenuItem>
                                    </Select>
                                </FormControl>

                            </div>
                            <div className="form-group">
                                <TextField
                                    type="text"
                                    label="Instruction"
                                    margin="normal"
                                    className="kt-width-full"
                                    name="instruction"
                                    onChange={(instruction) => this.setState({
                                        instruction: instruction.target.value
                                    })}
                                    value={instruction}
                                />
                            </div>


                            <Button variant="success" onClick={()=> this.handleSubmit()} style={{marginTop:15}}>Update</Button>


                        </div>


                    </Modal.Body>

                </Modal>


                {this.createUI()}

                {/*<input type='button' value='add more' onClick={this.addClick.bind(this)}/>*/}
                <Button variant="primary" onClick={this.addClick.bind(this)} style={{marginTop:15}}>Add Another Question</Button>
                {questions.length > 0 &&
                <Button variant="success" onClick={()=> this.handleSubmitQuestion()} style={{marginTop:15}}>Submit</Button>

                }
            </div>

        );
    }
}

export default ViewQuestions;
