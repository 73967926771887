import React, { Component } from 'react';
import autobind from 'react-autobind';
const moment = require('moment');

export const leadingZero = number => {
    // return zero if number type is niether string nur number
    if (typeof number !== 'string' && typeof number !== 'number') {
        return '0';
    }

    // convert number to string if number is a number
    if (typeof number === 'number') {
        number = number.toString();
    }

    // return number with zero prepended to it
    return number.length > 1 ? number : `0${number}`;
};

export default class Timer extends Component{

    state = {
        startTime: null,
        duration: null,
        remaining: null,
        ended: false
    }

    constructor(){
        super();
        autobind(this);
    }

    componentDidMount(){
        let startTime = this.props.startTime ? moment(this.props.startTime) : moment();
        this.setState({startTime, duration: this.props.duration});
    }

    componentDidUpdate(prevProps, prevState){
        if(prevState.startTime == null && this.state.startTime !== null){
            this.timer();
            this.start();
        }
        if(prevState.remaining > this.state.remaining && this.state.remaining < 1){
            this.stop();
            this.fireOnEnd();
        }
    }

    componentWillUnmount(){
        if(this.interval){
            clearInterval(this.interval);
        }
    }

    render(){
        const {
            startTime, 
            duration, 
            remaining, 
            ended
        } = this.state;

        if( startTime == null ||
            duration == null || 
            remaining == null || 
            ended
        ) {
            return null;
        }

        if(remaining < 1)
        {
            this.fireOnEnd();
            return null;
        }

        return (<h1>
            { this.showTime() }
        </h1>);
    }

    start(){
        this.interval = setInterval(this.timer, 1000);
    }

    timer(){
        const { startTime, duration } = this.state;
        let now = moment().get('time') / 1000;
        let deadline = (startTime.get('time') / 1000) + duration;
        let remaining = deadline - now;
        this.setState({remaining});
    }

    stop(){
        if(this.interval){
            clearInterval(this.interval);
        }
    }

    fireOnEnd(){
        this.setState({
            ended: true
        }, () => {
            if(typeof this.props.onEnd == 'function'){
                this.props.onEnd();
            }
        })
    }

    showTime(){
        const {remaining} = this.state;
        let days = Math.floor(remaining / ( 60 * 60 * 24));
        let hours = Math.floor((remaining%(60 * 60 * 24)) / (60 * 60));
        let minutes = Math.floor((remaining % (60 * 60)) / (60));
        let seconds = Math.floor((remaining % (60)));
        return `${days >= 1 ? leadingZero(days)+':' : ''}${hours >= 1 ? leadingZero(hours)+':' : ''}${leadingZero(minutes)}:${leadingZero(seconds)} left`;
    }
}