import React, {Component} from 'react';
import store, {persistor} from "../../../store/store";
import { connect } from 'react-redux';
import { ActionCreators } from  "../../../../app/store/ActionCreators";
import {bindActionCreators} from 'redux';
import { ActionTypes } from '../../../../../src/app/store/ducks/exams/constants/actionTypes';
import {http, endpoints} from '../../../config/http';
import {Button, Spinner} from "react-bootstrap";
import {isLoading} from "sweetalert2";
import {Link} from "react-router-dom";

class ExamHome extends Component {
    constructor() {
        super();
        this.state = {
            taken: false,
            isLoading:false,
            takenB: false,
            takenC:false,
            sessions:[]
        };
        this.loadExam = this.loadExam.bind(this);


    }
    componentDidMount(){
        const {
            auth: { user }
        } = store.getState();
        if (user.role != "student"){
            window.location.replace("/dashboard");
        }
        this.loadSession();

    }
    loadSession(){
        this.setState({
            isLoading: true
        });
        http
            .get(endpoints.EXAM_SESSION
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                    sessions: res.data
                })

            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });

    }
    loadExam(id){
        const config = {
            'allowBack': true,
            'allowReview': true,
            'autoMove': false,  // if true, it will move to next question automatically when answered.
            'duration': 5,  // indicates the time in which quiz needs to be completed. 0 means unlimited.
            'pageSize': 1,
            'requiredAll': false,  // indicates if you must answer all the questions before submitting.
            'richText': false,
            'shuffleQuestions': false,
            'shuffleOptions': false,
            'showClock': true,
            'showPager': true,
            'theme': 'none'
        };

            this.setState({
                isLoading: true
            });
            http
                .get(endpoints.EXAM_QUESTIONS.replace(':id', id)
                )
                .then(res => {
                    console.log(res);
                    this.setState({
                        isLoading :false,
                    })
                    console.log(res.data);
                    var result = res.data;
                    result.config = config;
                    this.props.onSubmit('quiz');
                    this.props.onUpdateTimer(null);
                    this.props.onQuizLoad(result);
                    window.location.replace('/exam');
                })
                .catch(error => {
                    this.setState({
                        isLoading: false
                    });
                    let errorMessage =
                        (typeof error === "string" && error) ||
                        "An error occured, please try again in a moment.";
                    // alert(JSON.stringify(error));
                    console.log(errorMessage);
                });



    }

    updateCbtScore(score){
        // console.log(this.props.quiz.score);
        http
            .put(endpoints.UPDATE_CBT_SCORE,{
                score: score
                }
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                })
                if (res.data.is_taken){
                    this.setState({
                        taken :true,
                    })
                }else{
                    this.setState({
                        taken :false,
                    })
                }
              // this.checkTaken();
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }

    checkTaken(){
        console.log(this.props.auth);
        http
            .get(endpoints.ME
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                })
                if (res.data.is_taken){
                    this.setState({
                        taken :true,
                    })
                }else{
                    this.setState({
                        taken :false,
                    })
                }
                if (res.data.is_taken_second){
                    this.setState({
                        takenB :true,
                    })
                }else{
                    this.setState({
                        takenB :false,
                    })
                }
                if (res.data.is_taken_third){
                    this.setState({
                        takenC :true,
                    })
                }else{
                    this.setState({
                        takenC :false,
                    })
                }
                // if (this.props.quiz.score !==  null && !res.data.is_taken && this.props.auth.user.id === this.props.quiz.userId){
                //     console.log("here");
                //     this.updateCbtScore(this.props.quiz.score);
                // }else {
                //     console.log("there");
                // }
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }

    getRowsData = function() {
        const {sessions,isLoading} = this.state;
        return sessions.map((item, index) => {
            return (
                <div className="kt-portlet" key={index}>
                    <div className="kt-portlet__head">
                        <div className="kt-portlet__head-label"><h3 className="kt-portlet__head-title">{item.subject.code}  - <span className="text-success">{item.completed_at && 'Exam Completed'}</span></h3></div>
                        <div className="kt-portlet__head-toolbar">

                        </div>
                    </div>
                    <div className="kt-portlet__body">
                        <div className="kt-portlet__preview">
                            <div className="kt-section">
                                <div>
                                    {item.subject.title}
                                </div>

                                <div className="kt-separator kt-separator--dashed"></div>
                                <button type="button" className="btn btn-primary" onClick={()=> this.loadExam(item.id)} disabled={isLoading || item.completed_at || item.started_at}>Start Exam
                                    {isLoading &&
                                    <Spinner animation="border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </Spinner>
                                    }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            );
        });
    };

    render() {
        const {taken, isLoading, takenB, takenC} = this.state;
        return (
            <div>
                <h2><span
                    className="kt-section__sub">Exams list for this semester</span></h2>

                {this.getRowsData()}


            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    quiz: state.quiz,
    auth: state.auth,
})
const mapDispatchToProps = dispatch => ({
    onQuizLoad: payload => dispatch({ type: ActionTypes.QuizLoad, payload }),
    onUpdateTimer: payload => dispatch({ type: ActionTypes.AStartTimeUpdate, payload }),
    onSubmit: payload => dispatch({ type: ActionTypes.QuizSubmit, payload }),
});

ExamHome = connect(mapStateToProps,mapDispatchToProps)(ExamHome)
export default ExamHome;
