import React, { Component } from "react";
import * as auth from "../../store/ducks/auth.duck";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";
// import { ActionCreators } from  "../../../store/ActionCreators";
import {bindActionCreators} from 'redux';

class Logout extends Component {
  componentDidMount() {
    this.props.logoutUser();
  }

  render() {
    const { hasAuthToken } = this.props;

    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
  }
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
          logoutUser: auth.actions.logout,
        },
        dispatch,
    );


export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  mapDispatchToProps
)(Logout);
