import React, {Component} from 'react';
import DateTimePicker from 'react-datetime-picker';
import './Sample.css';
import moment from "moment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {stateToHTML} from 'draft-js-export-html';
import Grid from '@material-ui/core/Grid';
import {Button} from "react-bootstrap";
import Swal from "sweetalert2";
import {http, endpoints} from '../../../config/http';
import MyLoader from './MyLoader';
import {TextField} from "@material-ui/core";

class CreateExam extends Component {
    constructor() {
        super();
        this.state = {
            subject: null,
            isLoading: false,
            value: new Date(),
            start_time: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
            duration: null,
            instruction: '',
            openCorrect: false,
            questions : [
                {
                    question: "",
                    editor: ""
                }
            ]
        }
        this.handleOpenCorrect = this.handleOpenCorrect.bind(this);
        this.handleCloseCorrect = this.handleCloseCorrect.bind(this);
    }

    componentDidMount(){

        this.setupCourse();

    }
    handleCloseCorrect() {
        this.setState({ openCorrect: false });

    }

    handleOpenCorrect() {
        this.setState({ openCorrect: true });
    }
    setupCourse(){
        const { subject, course, level } = this.props.location.state;
        this.setState({
            subject
        });
        // this.props.history.push('theory-subjects',{
        //     course, level
        //
        // });
    }
    onChange = value => {
        this.setState({
            value ,
            start_time: moment(value).format('YYYY-MM-DDTHH:mm:ss.sssZ')
        });


    }
    handleSelectChange = duration => {this.setState({
        duration
    })}
    createUI(){
        return this.state.questions.map((el, i) =>
            <div key={i} style={{marginTop:15}}>
                {/*<input type="text" value={el.question||''} onChange={this.handleChange.bind(this, i)} />*/}

                <h3>Question {i+1}</h3>
                <Grid container spacing={3}>
                    <Grid item xs={10}>
                        <Editor
                            ref = {'editor'+i}
                            editorState={el.editor}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="demoWrap"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.handleChange.bind(this, i)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="danger" onClick={this.removeClick.bind(this, i)}>Remove</Button>
                        {/*<input type='button' value='remove' onClick={this.removeClick.bind(this, i)}/>*/}
                    </Grid>

                </Grid>
            </div>
        )
    }

    handleChange(i, event) {
        let questions = [...this.state.questions];
        questions[i].editor = event;
        questions[i].question = stateToHTML(event.getCurrentContent());
        this.setState({ questions });
    }

    addClick(){
        this.setState(prevState => ({ questions: [...prevState.questions, {question: ''}]}))
    }

    removeClick(i){
        let questions = [...this.state.questions];
        questions.splice(i,1);
        this.setState({ questions });
    }

    handleSubmit = () => {
        const { course, subject, level } = this.props.location.state;
        const {duration, start_time, questions, instruction} = this.state;

        Swal.fire({
            title:
                "Are you sure you want to create exam "
            ,
            text:
                "You won't be able to revert this this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Create",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isLoading: true
                });
                http
                    .post(endpoints.CREATE_THEORY_EXAM,{duration, start_time, questions,subject: subject.id,instruction

                        }
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "exam created "
                        });
                        this.setState({
                            isLoading: false
                        });
  setTimeout(()=>{
      this.props.history.push('theory-subjects',{
          course, level

      });
  }, 2000);

                    })
                    .catch(error => {
                        this.setState({
                            isLoading: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };
    render() {
        const {subject, isLoading, value, duration, questions, instruction} = this.state;
        return (
            <MyLoader active={isLoading}>
            <div className="container">
                <div>
                    {subject &&
                    <div>
                        <h2>{subject.title} - Theory Exam </h2>

                    </div>
                    }
                </div>
                <div className="Sample mTop">
                    <header>
                        <h2>Select Exam date and time</h2>
                    </header>
                    <div className="Sample__container">
                        <main className="Sample__container__content">
                            <DateTimePicker
                                amPmAriaLabel="Select AM/PM"
                                calendarAriaLabel="Toggle calendar"
                                clearAriaLabel="Clear value"
                                dayAriaLabel="Day"
                                hourAriaLabel="Hour"
                                maxDetail="second"
                                closeWidgets={true}
                                minuteAriaLabel="Minute"
                                monthAriaLabel="Month"
                                nativeInputAriaLabel="Date and time"
                                onChange={this.onChange}
                                secondAriaLabel="Second"
                                value={value}
                                minDate={new Date()}
                                yearAriaLabel="Year"
                            />
                        </main>
                    </div>

                    <div className="form-group mTop">

                        <FormControl style={{minWidth: 120}}>
                            <InputLabel htmlFor="demo-controlled-open-select">Exam Duration</InputLabel>
                            <Select
                                open={this.state.openCorrect}
                                onClose={this.handleCloseCorrect}
                                onOpen={this.handleOpenCorrect}
                                value={duration}
                                onChange={(duration)=> this.handleSelectChange(duration.target.value)}
                                inputProps={{
                                    name: 'correct',
                                    id: 'demo-controlled-open-select',
                                }}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value="15">15 minutes</MenuItem>
                                <MenuItem value="30">30 minutes</MenuItem>
                                <MenuItem value="45">45 minutes</MenuItem>
                                <MenuItem value="60">60 minutes</MenuItem>
                                <MenuItem value="90">90 minutes</MenuItem>
                                <MenuItem value="120">120 minutes</MenuItem>
                            </Select>
                        </FormControl>

                    </div>

                    <div className="form-group">
                        <TextField
                            type="text"
                            label="Instruction"
                            margin="normal"
                            className="kt-width-full"
                            name="instruction"
                            onChange={(instruction) => this.setState({
                                instruction: instruction.target.value
                            })}
                            value={instruction}
                        />
                    </div>

                    {this.createUI()}

                    {/*<input type='button' value='add more' onClick={this.addClick.bind(this)}/>*/}
                    <Button variant="primary" onClick={this.addClick.bind(this)} style={{marginTop:15}}>Add Another Question</Button>
                    {questions.length > 0 &&
                    <Button variant="success" onClick={()=> this.handleSubmit()} style={{marginTop:15}}>Submit</Button>

                    }
                </div>

            </div>
            </MyLoader>
        );
    }
}

export default CreateExam;
