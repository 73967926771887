import React, {Component} from 'react';
import Timer from "./Timer";
import "../../../App.css";
import { toAbsoluteUrl } from "../../../../src/_metronic";
import {Table, Button, Spinner} from "react-bootstrap";
import { ActionTypes } from '../../../../src/app/store/ducks/exams/constants/actionTypes';
import {connect} from "react-redux";
import Swal from "sweetalert2";
import {http, endpoints} from '../../config/http';

const moment = require('moment');


class SectionB extends Component {


    constructor() {
        super();
        this.state = {
            started_at:null,
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            q6: "",
            q7: "",
            q8:"",
            q9:"",
            q10:"",
            q11: "",
            q12: "",
            isSubmitting: false,
            done:false,
            isLoading: false,
            taken:false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submit = this.submit.bind(this);
    }
    componentDidMount(){
        this.setupDate();
        this.checkTaken();
        // window.onbeforeunload = function() {
        //     this.onUnload();
        //     alert("hello")
        //     return "";
        // }.bind(this);
    }

    checkTaken(){
        http
            .get(endpoints.ME
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                })
                if (res.data.is_taken_second){
                    this.setState({
                        taken :true,
                    })
                    alert("Exam alreadry taken");
                    window.location.replace("/take-exam");
                    this.props.onUpdateTimer(null);
                }else{
                    this.setState({
                        taken :false,
                    })
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    setupDate(){
        var CurrentDate = new Date();
        console.log(CurrentDate);
        if (this.props.BStartTime === null){
            var stat = new Object();
            stat.userId = this.props.user.id;
            stat.time = CurrentDate
            this.props.onUpdateTimer(stat);
        }
        else {
            console.log("here" +this.props.BStartTime);
        }

        console.log(this.props.BStartTime);
        console.log(this.state.started_at);


    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }

    submit(){
        this.setState({
            isSubmitting: true
        });
        const {q1,q2,q3,q4,q5,q6,q7,q8,q9, q10, q11, q12} = this.state;
        http
            .post(endpoints.SUBMIT_SECOND,{
                    q1,q2,q3,q4,q5,q6,q7,q8,q9, q10, q11, q12
                }
            )
            .then(res => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    icon: "success",
                    title: "Exam submitted"
                });
                this.setState({
                    isSubmitting: false,
                    done:true,
                    q1: "",
                    q2: "",
                    q3: "",
                    q4: "",
                    q5: "",
                    q6: "",
                    q7: "",
                    q8:"",
                    q9:"",
                    q10:"",
                    q11: "",
                    q12: "",
                });
                this.props.onUpdateTimer(null);
                setTimeout(()=>{
                    window.location.replace("/take-exam");
                }, 3000);


            })
            .catch(error => {
                this.setState({
                    isSubmitting: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";

                const Toast = Swal.mixin({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    icon: "error",
                    title: errorMessage
                });
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    handleSubmit(event){
        event.preventDefault();
        Swal.fire({
            title:
                "Are you sure you want to submit exam "
            ,
            text:
                "You won't be able to revert this this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Submit",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.submit();
            }
        });

    }


    render() {
        const {q1,q2,q3,q4,q5,q6,q7,q8,q9, q10, q11, q12, done, isSubmitting} = this.state;
        const timerdate = this.props.BStartTime === null? new Date() : this.props.BStartTime.time;
        return (
            <div className="container">
                <header className="p-2 mheader container">
                    <div className="row">
                        <div className="col-6">
                            <img src="/media/logos/logo-lights.png" className="logoImage"/>
                        </div>
                        {!done &&
                        <div className="col-6 text-center">
                            <label className="mr-1">Time:</label>
                            <Timer
                                style={[]}
                                startTime={timerdate}
                                duration={30 * 60}
                                onEnd={this.submit}
                                intervalText={(date, hour, min, sec) =>
                                    `${min}:${sec} left`
                                }
                            />

                        </div>
                        }

                    </div>
                </header>

                <div className="text-center m-t-50" style={{marginTop: 150, color: 'black'}}>
                    <h2 className="text-black">Schreiben</h2>
                </div>

                <div className="text-center m-t-50" style={{marginTop: 150, color: 'black'}}>
                    <h3 className="text-black">Zeit: 30 Minuten</h3>
                    <ul className="homelist">
                        <li>Schreibe deine Lösungen auf das Lösungsblatt.</li>
                        <li>Du darfst mit Anderen nicht sprechen.</li>
                        <li>Du darfst mit Anderen nicht sprechen.</li>
                    </ul>
                </div>

                <div className="m-t-50" style={{marginTop: 150, color: 'black', overflow:'auto'}}>
                    <p className="question_p">Aufgabe 1: Formular <i>(15 Minuten)</i></p>

                    <p className="question_p2">Du möchtest tanzen lernen. Die Tanzschule hat zwei Kurse für Schüler: einen an Wochentagen (Di und Do, 17-18 Uhr) oder einen am Sonntag (16-18 Uhr). Melde dich zu einem Kurs.</p>

                    <ul style={{float:'left'}}>
                        <li>Fülle dieses Formular auf dem Lösungsblatt aus.</li>
                        <li>Zu den Punkten 1-4: Schreibe ganz kurze Antworten.</li>
                        <li> Zu Punkt 5 und 6: Schreibe ganze Sätze.</li>
                    </ul>
                    <img  src={toAbsoluteUrl("/media/exam/q1.png")}  className="question_image" />
                </div>
                <div style={{clear:"both"}}></div>
                <div className="m-t-50" style={{marginTop: 10, color: 'black'}}>
                    <p className="question_p">Aufgabe 2: Brief <i>(15 Minuten)</i></p>

                    <p className="question_p2">Du (Gabi) besuchst eine Musikschule und spielst sehr gut Klavier. Nächste Woche gibt es ein Konzert, wo du ein Mozart-Stück spielst. Lade deine Freundin zum Konzert ein!</p>
                    <div className="conatiner hash_back">
                        Du schreibst ihr <strong>eine E-Mail</strong>. Schreib ca. 2 Sätze zu jeder Frage.
                        <ul>
                            <li>Konzert wann genau und wo?</li>
                            <li>Konzertprogramm?</li>
                            <li>Nach dem Konzert Eis essen?</li>
                        </ul>
                    </div>
                    <div className="text-center">
                    <img  src={toAbsoluteUrl("/media/exam/q2.png")}  className="question_image2" />
                    </div>
                </div>

                <div className="text-center m-t-50" style={{marginTop: 100, color: 'black'}}>
                    <h2 className="text-black">SCHREIBEN – LÖSUNGSBLATT – AUFGABE 1</h2>
                </div>



                <form onSubmit={this.handleSubmit} >

                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th colSpan={3} className="hash_back"><h2>Anmeldung zum Tanzkurs</h2></th>

                        </tr>
                        <tr>
                            <th>#</th>
                            <th>Name:</th>
                            <th>Müller</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td></td>
                            <td>Vorname:</td>
                            <td>
                                 <input placeholder="" type="text" id="formBasicEmail" name="q1" className="form-control" onChange={this.handleChange} value={q1}/>
                            </td>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td>Geburtsjahr:</td>
                            <td>
                                <input placeholder="" type="text" className="form-control" value={q2} name="q2" onChange={this.handleChange} />
                            </td>

                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Geburtsort:</td>
                            <td>
                                <input placeholder="" type="text"  className="form-control" value={q3} name="q3" onChange={this.handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Wohnort, Straße:</td>
                            <td>
                                <input placeholder="" type="text"  className="form-control" value={q4} name="q4" onChange={this.handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Größe:</td>
                            <td>
                                <input placeholder="" type="text"  className="form-control" value={q5} name="q5" onChange={this.handleChange} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} className="text-center"><h3>Antworte bitte in 1-2 ganzen Sätzen:</h3></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td colSpan={2}>
                                <label className="form-label" htmlFor="q6">Woher kennst du unsere Tanzschule?</label>
                                <textarea className="form-control"
                                          id="q6"
                                          name="q6"
                                          onChange={this.handleChange}
                                          value={q6}
                                          rows="5"/>
                            </td>
                        </tr>

                        <tr>
                            <td>6</td>
                            <td colSpan={2}>
                                <label className="form-label" htmlFor="q7">Welchen Kurs möchtest du besuchen? Kommst du allein oder mit Tanzpartner/in?</label>
                                <textarea className="form-control"
                                          id="q7"
                                          name="q7"
                                          onChange={this.handleChange}
                                          value={q7}
                                          rows="5"/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>


                    <div className="text-center m-t-50" style={{marginTop: 100, color: 'black'}}>
                        <h2 className="text-black">SCHREIBEN – LÖSUNGSBLATT – AUFGABE 2</h2>
                    </div>

                    <label className="form-label" >
                        <p className="section2label">
                            Liebe Angelika,<br/>
                            ich möchte dich zu einem Konzert einladen:
                        </p>
                    </label>

                    <div className="mb-3 input-group">
                        <textarea
                            aria-label="Wann und wo?"
                            name='q8'
                            value={q8}
                            onChange={this.handleChange}
                            aria-describedby="basic-addon2" className="form-control"
                            rows={5}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text text-dark" id="basic-addon2">Wann und wo?</span>
                        </div>
                    </div>
                    <div className="mb-3 input-group">
                        <textarea
                            name='q9'
                            value={q9}
                            onChange={this.handleChange}
                            aria-label="Konzertprogramm?"
                            aria-describedby="basic-addon3" className="form-control"
                            rows={5}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text text-dark" id="basic-addon3">Konzertprogramm?</span>
                        </div>
                    </div>

                    <div className="mb-3 input-group">
                        <textarea
                            name='q10'
                            value={q10}
                            onChange={this.handleChange}
                            aria-label="Nach dem Konzert?"
                            aria-describedby="basic-addon4" className="form-control"
                            rows={5}
                        />
                        <div className="input-group-append">
                            <span className="input-group-text text-dark" id="basic-addon4">Nach dem Konzert?</span>
                        </div>
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="q11">Ich freue mich, wenn du kommen kannst.</label>
                        <input
                        placeholder="" id="q11" className="form-control" value={q11} name="q11" onChange={this.handleChange}/>
                    </div>

                    <div className="form-group">
                        <label className="form-label" htmlFor="q12">Gabi</label>
                        <input
                            placeholder="" id="q12" className="form-control" value={q12} name="q12" onChange={this.handleChange}/>
                    </div>

                    <div>
                        <Button variant="primary" size="lg" block type="submit" style={{marginBottom: 200}} disabled={isSubmitting}>
                            Submit           {isSubmitting &&
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        }
                        </Button>
                    </div>


                </form>



            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    onUpdateTimer: payload => dispatch({ type: ActionTypes.BStartTimeUpdate, payload })
});
const mapStateToProps = (state) => ({
    BStartTime: state.quiz.BStartTime,
    user: state.auth.user
})

export default connect(mapStateToProps, mapDispatchToProps)(SectionB);