import React, {Component} from 'react';
import Timer from "./Timer";
import "../../../App.css";
import { toAbsoluteUrl } from "../../../../src/_metronic";
import {Table, Button, Spinner, Card} from "react-bootstrap";
import { ActionTypes } from '../../../../src/app/store/ducks/exams/constants/actionTypes';
import {connect} from "react-redux";
import Swal from "sweetalert2";
import {http, endpoints} from '../../config/http';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const moment = require('moment');


class SectionC extends Component {


    constructor() {
        super();
        this.state = {
            started_at:null,
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            q6: "",
            q7: "",
            q8:"",
            q9:"",
            q10:"",
            q11: "",
            q12: "",
            q13: "",
            q14: "",
            q15: "",
            isSubmitting: false,
            done:false,
            isLoading: false,
            taken:false
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.submit = this.submit.bind(this);
    }
    componentDidMount(){
        this.setupDate();
        this.checkTaken();
        // window.onbeforeunload = function() {
        //     this.onUnload();
        //     alert("hello")
        //     return "";
        // }.bind(this);
    }

    checkTaken(){
        http
            .get(endpoints.ME
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                })
                if (res.data.is_taken_third){
                    this.setState({
                        taken :true,
                    })
                    alert("Exam alreadry taken");
                    window.location.replace("/take-exam");
                    this.props.onUpdateTimer(null);
                }else{
                    this.setState({
                        taken :false,
                    })
                }
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    setupDate(){
        var CurrentDate = new Date();
        console.log(CurrentDate);
        if (this.props.CStartTime === null){
            var stat = new Object();
            stat.userId = this.props.user.id;
            stat.time = CurrentDate
            this.props.onUpdateTimer(stat);
        }
        else {
            console.log("here" +this.props.CStartTime);
        }

        console.log(this.props.CStartTime);
        console.log(this.state.started_at);


    }

    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }

    submit(){
        this.setState({
            isSubmitting: true});
        const {q1,q2,q3,q4,q5,q6,q7,q8,q9, q10, q11, q12, q13,q14,q15} = this.state;
        http
            .post(endpoints.SUBMIT_THIRD,{
                    q1,q2,q3,q4,q5,q6,q7,q8,q9, q10, q11, q12,q13,q14,q15
                }
            )
            .then(res => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    icon: "success",
                    title: "Exam submitted"
                });
                this.setState({
                    isSubmitting: false,
                    done:true,
                    q1: "",
                    q2: "",
                    q3: "",
                    q4: "",
                    q5: "",
                    q6: "",
                    q7: "",
                    q8:"",
                    q9:"",
                    q10:"",
                    q11: "",
                    q12: "",
                    q13:"",
                    q14: "",
                    q15: "",
                });
                this.props.onUpdateTimer(null);
                setTimeout(()=>{
                    window.location.replace("/take-exam");
                }, 3000);


            })
            .catch(error => {
                this.setState({
                    isSubmitting: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";

                const Toast = Swal.mixin({
                    toast: true,
                    position: "bottom-end",
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    icon: "error",
                    title: errorMessage
                });
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    handleSubmit(event){
        event.preventDefault();
        Swal.fire({
            title:
                "Are you sure you want to submit exam "
            ,
            text:
                "You won't be able to revert this this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Submit",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.submit();
            }
        });

    }


    render() {
        const {q1,q2,q3,q4,q5,q6,q7,q8,q9, q10, q11, q12, q13, q14, q15, done, isSubmitting} = this.state;
        const timerdate = this.props.CStartTime === null? new Date() : this.props.CStartTime.time;
        return (
            <div className="container parent">
                <header className="p-2 mheader container">
                    <div className="row">
                        <div className="col-6">
                            <img src="/media/logos/logo-lights.png" className="logoImage"/>
                        </div>
                        {!done &&
                        <div className="col-6 text-center">
                            <label className="mr-1">Time:</label>
                            <Timer
                                style={[]}
                                startTime={timerdate}
                                duration={20 * 60}
                                onEnd={this.submit}
                                intervalText={(date, hour, min, sec) =>
                                    `${min}:${sec} left`
                                }
                            />

                        </div>
                        }

                    </div>
                </header>
                {/*<ReactAudioPlayer*/}
                {/*    src="/media/exam/german.mp3"*/}
                {/*    controls*/}
                {/*/>*/}


                <div className="text-center m-t-50" style={{marginTop: 150, color: 'black'}}>
                    <h2 className="text-black">Hören</h2>
                </div>


                <div className="text-center m-t-50" style={{marginTop: 150, color: 'black'}}>
                    <h3 className="text-black">ca . 20 Minuten</h3>
                    <ul className="homelist">
                        <li>Dieser Test hat drei Teile.</li>
                        <li>Lesen Sie zuerst die Aufgaben,</li>
                        <li>hören Sie dann den Text dazu.</li>
                        <li>Schreiben Sie zum Schluss Ihre Lösungen auf den Antwortbogen.</li>
                    </ul>
                </div>

                <div className="row" style={{marginTop: 150, color: 'black', overflow:'auto'}}>

                    <div className="col-6">
                        <p className="question_p">Hören, Teil 1</p>
                        <p className="question_p2" style={{fontStyle:'italic'}}>Sie hören fünf Ansagen am Telefon. Zu jedem Text gibt es eine Aufgabe. Ergänzen Sie die Telefonnotizen. Sie hören jeden Text zweimal.</p>

                    </div>
                    <div className="col-6">
                    <Card border="primary">
                        <Card.Header style={{fontWeight:'bold'}}>
                          0 Beispeil

                        </Card.Header>
                        <Card.Body>
                            <Card.Title>Werkstatt</Card.Title>
                            <Card.Text>
                                <p>Auto abohen</p>
                                <p>Zeit: <span style={{textDecoration: 'underline'}}>ab 8.30 Uhr</span></p>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                    </div>

                </div>
                <form onSubmit={this.handleSubmit} >
                <div className="row" style={{marginTop: 20, color: 'black', overflow:'auto'}}>

                    <div className="col-md-6 m-t-15">
                        <Card border="primary">
                            <Card.Header style={{fontWeight:'bold'}}>
                                1  Telefonauskunft

                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <p>Vorwahl: 069</p>
                                    <p>Rufnummer: <input placeholder="" type="text" id="" className="form-control" value={q1} name="q1" onChange={this.handleChange} /></p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                    <div className="col-md-6 m-t-15">
                        <Card border="primary">
                            <Card.Header style={{fontWeight:'bold'}}>
                                2   Dimitri zurückrufen

                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <p>Treffen – wann?</p>
                                    <p>nicht: <input placeholder="" type="text" id="" className="form-control" value={q2} name="q2" onChange={this.handleChange} /></p>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                </div>

                    <div className="row" style={{ color: 'black', overflow:'auto'}}>

                        <div className="col-md-6 m-t-15">
                            <Card border="primary">
                                <Card.Header style={{fontWeight:'bold'}}>
                                    3  Sascha

                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <p>Treffpunkt mit Sascha</p>
                                        <p>Wo? <input placeholder="" type="text" id="" className="form-control" value={q3} name="q3" onChange={this.handleChange} /></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 m-t-15">
                            <Card border="primary">
                                <Card.Header style={{fontWeight:'bold'}}>
                                    4   Frankfurt > Köln

                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <p>Abfahrt: 11.45 Uhr</p>
                                        <p>Preis:<input placeholder="" type="text" id="" className="form-control" value={q4} name="q4" onChange={this.handleChange} /></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className="col-md-6 m-t-15">
                            <Card border="primary">
                                <Card.Header style={{fontWeight:'bold'}}>
                                    5  Firma Keller

                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <p>Arbeit – wann?</p>
                                        <p>Dienstagvormittag: <input placeholder="" type="text" id="" className="form-control" value={q5} name="q5" onChange={this.handleChange} /></p>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>


                    </div>
                <div style={{clear:"both"}}></div>
                <div className="m-t-50" style={{marginTop: 50, color: 'black'}}>
                    <p className="question_p">Hören, Teil 2</p>

                    <p className="question_p2" style={{fontStyle: 'italic'}}>
                        Sie hören fünf Informationen aus dem Radio.<br/>
                        Zu jedem Text gibt es eine Aufgabe.<br/>
                        Kreuzen Sie an: a, b oder c.<br/>
                        Sie hören jeden Text einmal.</p>
                    <div className="hash_back">

                        <strong> Beispiel</strong>
                        <p className="question_p2">0  Wie spät ist es gleich?</p>

                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioa" className="form-check-input is-valid" name="example" value="a" disabled/>
                                <label htmlFor="check-api-radioa" className="">  acht Uhr am Morgen</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radiob" className="form-check-input is-valid" name="example" value="b" checked disabled/>
                                <label htmlFor="check-api-radiob" className="">    sechs Uhr am Abend</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioc" className="form-check-input is-valid" name="example" value="c" disabled/>
                                <label htmlFor="check-api-radioc" className="">    sechs Uhr am Abend</label>

                            </div>
                        </div>
                    </div>

                </div>

                    <div className="" style={{marginTop: 50, color: 'black'}}>


                        <p className="question_p2">6. Was kommt nachmittags um drei Uhr?</p>

                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq6a" className="form-check-input is-valid" name="q6" value="a" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq6a" className="">  das Mittagsprogramm</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq6b" className="form-check-input is-valid" name="q6" value="b" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq6b" className="">    eine Sendung für Kinder</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq6c" className="form-check-input is-valid" name="q6" value="c" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq6c" className="">    Nachrichten</label>

                            </div>
                        </div>
                    </div>
                    <div className="" style={{marginTop: 50, color: 'black'}}>
                        <p className="question_p2">7. Wie wird das Wetter morgen?</p>

                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq7a" className="form-check-input is-valid" name="q7" value="a" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq7a" className="">  Es gibt ein Gewitter.</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq7b" className="form-check-input is-valid" name="q7" value="b" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq7b" className="">    Es gibt Regen.</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq7c" className="form-check-input is-valid" name="q7" value="c" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq7c" className="">    Es wird warm.</label>

                            </div>
                        </div>
                    </div>
                    <div className="" style={{marginTop: 50, color: 'black'}}>
                        <p className="question_p2">8. Wer oder was läuft auf der Straße?</p>

                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq8a" className="form-check-input is-valid" name="q8" value="a" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq8a" className="">  ein Autofahrer</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq8b" className="form-check-input is-valid" name="q8" value="b" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq8b" className="">    ein Kind</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq8c" className="form-check-input is-valid" name="q8" value="c" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq8c" className="">    Eein Tier</label>

                            </div>
                        </div>
                    </div>
                    <div className="" style={{marginTop: 50, color: 'black'}}>
                        <p className="question_p2">9. Wer gratuliert zum Geburtstag?</p>

                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq9a" className="form-check-input is-valid" name="q9" value="a" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq9a" className="">  Josef, ein junger Mannr</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq9b" className="form-check-input is-valid" name="q9" value="b" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq9b" className="">    Josefs Familie</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq9c" className="form-check-input is-valid" name="q9" value="c" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq9c" className="">    Josefs Kollege aus dem Radio</label>

                            </div>
                        </div>
                    </div>
                    <div className="" style={{marginTop: 50, color: 'black'}}>
                        <p className="question_p2">10. Was kann man gewinnen?</p>

                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq10a" className="form-check-input is-valid" name="q10" value="a" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq10a" className="">   eine Reise</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq10b" className="form-check-input is-valid" name="q10" value="b" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq10b" className="">    ein Buch</label>

                            </div>
                        </div>
                        <div className="mb-3">
                            <div className="form-check">
                                <input type="radio" id="check-api-radioq10c" className="form-check-input is-valid" name="q10" value="c" onChange={this.handleChange}/>
                                <label htmlFor="check-api-radioq10c" className="">    ein Spiel</label>

                            </div>
                        </div>
                    </div>

                    <div className="m-t-50" style={{marginTop: 50, color: 'black'}}>
                        <p className="question_p">Hören, Teil 3</p>

                        <p className="question_p2" style={{fontStyle: 'italic'}}>
                            Sie hören ein Gespräch.<br/>
                            Zu diesem Gespräch gibt es fünf Aufgaben.<br/>
                            Ordnen Sie zu und notieren Sie den Buchstaben.<br/>
                            Sie hören den Text zweimal.

                        </p>


                    </div>






                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th colSpan={3} className="hash_back"><h2>0 Beispiel </h2> Wo findet man diese Personen?
                                Chef   <span style={{float:'right'}}>Lösung: a Im ersten Stock</span></th>

                        </tr>
                        <tr>
                            <th>#</th>
                            <th>Wen?</th>
                            <th>Wo?</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>0 </td>
                            <td>Chef</td>
                            <td>
                                <input placeholder="" type="text" id="formBasicEmailhere"  className="form-control"  value="a" disabled/>
                            </td>
                        </tr>
                        <tr>
                            <td>11</td>
                            <td>Leiterin Export</td>
                            <td>
                                <input placeholder="" type="text" id="formBasicEmail" name="q11" className="form-control" onChange={this.handleChange} value={q11}/>
                            </td>
                        </tr>
                        <tr>
                            <td>12</td>
                            <td>
                                Sohn vom Chef</td>
                            <td>
                                <input placeholder="" type="text"  className="form-control" value={q12} name="q12" onChange={this.handleChange} />
                            </td>

                        </tr>
                        <tr>
                            <td>13</td>
                            <td>
                                Haus arbeiter</td>
                            <td>
                                <input placeholder="" type="text"  className="form-control" value={q13} name="q12" onChange={this.handleChange} />
                            </td>

                        </tr>
                        <tr>
                            <td>14</td>
                            <td>
                                Praktikantin</td>
                            <td>
                                <input placeholder="" type="text"  className="form-control" value={q14} name="q14" onChange={this.handleChange} />
                            </td>

                        </tr>
                        <tr>
                            <td>15</td>
                            <td>
                                Sekretärin</td>
                            <td>
                                <input placeholder="" type="text"  className="form-control" value={q15} name="q15" onChange={this.handleChange} />
                            </td>

                        </tr>





                        </tbody>
                    </Table>
                    <div>


                        <Button variant="primary" size="lg" block type="submit" style={{marginBottom: 200, marginTop: 25}} disabled={isSubmitting}>
                            Submit
                            {isSubmitting &&
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                        }
                        </Button>

                    </div>


                </form>
<div className="sticky">
                <AudioPlayer
                    autoPlay
                    src="/media/exam/german.mp3"
                    onPlay={e => console.log("onPlay")}
                    // other props here
                />
</div>

                    {/*<div className="sticky"><strong>position: sticky and bottom 10px</strong><br/>This div is sticky.*/}
                    {/*</div>*/}


            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    onUpdateTimer: payload => dispatch({ type: ActionTypes.CStartTimeUpdate, payload })
});
const mapStateToProps = (state) => ({
    CStartTime: state.quiz.CStartTime,
    user: state.auth.user
})

export default connect(mapStateToProps, mapDispatchToProps)(SectionC);