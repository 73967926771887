export const actionTypes = {
    GetExams: "[Get Exam] Action",
    ExamLoaded: "[ExamLoaded] Exam API",
    ExamFailure: "[ExamSessionFailed] Failed To load",
    GetExamsList: "[Get Exam List] Action",
    ExamListLoaded: "[ExamListLoaded] Exam API",
    ExamListFailure: "[ExamListFailed] Failed To load",
    QuizLoad: 'QuizLoad',
    QuizAnswer: 'QuizAnswer',
    QuizSubmit: 'QuizSubmit',
    PagerUpdate: 'PagerUpdate',
    SetScore: 'SetScore',
    UpdateExamCbtScore: "UpdateCbtScore",
    BStartTimeUpdate:  "BStartTime",

    AStartTimeUpdate:  "AStartTime",

    CStartTimeUpdate:  "CStartTime",

};