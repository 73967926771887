import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Button} from "react-bootstrap";
import { ActionTypes } from '../../../../src/app/store/ducks/exams/constants/actionTypes';
import {http, endpoints} from '../../../app/config/http';

class Result extends Component {
    constructor() {
        super();
        this.state = {
            questions : [],
            isLoading:false,
        };
    }
    componentDidMount(){
        let questions = this.props.questions;
        questions.forEach(q => { q.isCorrect = q.options.every(x => x.selected === x.is_correct_answer); })
        this.setState({
            questions: questions
        })

       var mresult = questions.filter(function(question) {
            return question.isCorrect == true
        });
        console.log(mresult);
        var mscore = new Object();
        mscore.score = mresult.length;
        mscore.userId = this.props.user.id;
        this.props.SetScore(mscore);
        console.log(this.props.user)
        this.updateCbtScore(mresult.length)
        this.props.onUpdateTimer(null);

    }

    updateCbtScore(score){
        // console.log(this.props.quiz.score);
        http
            .put(endpoints.UPDATESCORE,{
                    score: score,
                id:this.props.quiz.session_id
                }
            )
            .then(res => {
                console.log(res);
                this.props.SetScore(null);
                // this.checkTaken();
            })
            .catch(error => {

                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    render() {
        const {questions} = this.state;
        return (
            <div className="result">
                <h2 className="text-center font-weight-normal">Exam Completed</h2>


                <Link
                    to="/take-exam"
                    className="kt-link kt-login__link-forgot m-l-5"
                    style={{marginLeft:10}}
                >
                    <Button variant="secondary">Go Back</Button>
                </Link>
                {/*{questions.map((q, index) =>*/}
                {/*    <div key={q.id} className={`mb-2 ${q.isCorrect ? 'bg-success' : 'bg-danger'}`}>*/}
                {/*        <div className="result-question">*/}
                {/*            <h5>{index + 1}. {q.name}</h5>*/}
                {/*            <div className="row">*/}
                {/*                {*/}
                {/*                    q.options.map(option =>*/}
                {/*                        <div key={option.id} className="col-6">*/}
                {/*                            <input id={option.id} type="checkbox" disabled="disabled" checked={option.selected} /> {option.name}*/}
                {/*                        </div>*/}
                {/*                    )*/}
                {/*                }*/}
                {/*            </div>*/}
                {/*            <div className={`m-1 p-1 text-bold ${q.isCorrect ? 'text-success' : 'text-danger'}`}>Your answer is {q.isCorrect ? 'Correct' : 'Wrong'}.</div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*)}*/}


            </div>
        );
    }
}
const mapStateToProps = state => { return { ...state.quiz, ...state.mode, ...state.pager, ...state.auth } };

const mapDispatchToProps = dispatch => ({
    SetScore: payload => dispatch({ type: ActionTypes.SetScore, payload }),
    onUpdateTimer: payload => dispatch({ type: ActionTypes.AStartTimeUpdate, payload })
});

export default connect(mapStateToProps, mapDispatchToProps)(Result);
