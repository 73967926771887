import React, {Component} from 'react';
import DateTimePicker from "react-datetime-picker";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Button} from "react-bootstrap";
import Swal, {isLoading} from "sweetalert2";
import {http, endpoints} from '../../../config/http';
import Grid from "@material-ui/core/Grid";
import {Editor} from "react-draft-wysiwyg";
import MyLoader from '../admin/MyLoader';
import {stateToHTML} from "draft-js-export-html";
import Timer from "../../session/Timer";
import {Link} from "react-router-dom";
var ReactDOMServer = require('react-dom/server');
var HtmlToReactParser = require('html-to-react').Parser;

class TheoryExamSession extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subject: null,
            isLoading: false,
            subject: [],
            exam: null,
            duration: '',
            value: new Date(),
            questions: [],
            errorMessage:null,
            timeLeft: 60,
            mode: 'exam',
            instruction: '',
            status: false,

        }
    }
    componentDidMount(){
        this.setupCourse();
    }
    setupCourse(){
        const { subject } = this.props.location.state;
        this.setState({
            subject
        });
        this.loadQuestions(subject.id);
    }
    loadQuestions(id){
        this.setState({
            isLoading: true
        });
        http
            .get(endpoints.EXAM_QUESTIONS_THEORY_SESSION.replace(':id', id)
            )
            .then(res => {
                console.log(res.data);
                this.setState({
                    isLoading :false,
                    exam: res.data,
                    questions: res.data.questions,
                    duration: res.data.duration,
                    timeLeft: res.timer,
                    instruction: res.data.instruction,
                    value: new Date(res.data.start_time
                    )
                })
            })
            .catch(error => {

                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);

                this.setState({
                    isLoading: false,
                    errorMessage
                });
            });
    }
    handleChange(i, event) {
        let questions = [...this.state.questions];
        questions[i].editor = event;
        questions[i].answer = stateToHTML(event.getCurrentContent());
        this.setState({ questions });
    }

    addClick(){
        this.setState(prevState => ({ questions: [...prevState.questions, {question: ''}]}))
    }
    createUI(){

        return this.state.questions.map((el, i) => {
            var htmlInput = el.question;
            var htmlToReactParser = new HtmlToReactParser();
            var reactElement = htmlToReactParser.parse(htmlInput);
            var reactHtml = ReactDOMServer.renderToStaticMarkup(reactElement);
            return (
            <div key={i} style={{marginTop: 15}}>
                {/*<input type="text" value={el.question||''} onChange={this.handleChange.bind(this, i)} />*/}

                <h4>Question {i + 1}</h4>
                <p>{reactElement}</p>
                <Grid container spacing={3}>
                    <Grid item>
                        <Editor
                            ref={'editor' + i}
                            editorState={el.editor}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="demoWrap"
                            editorClassName="editorClassName"
                            onEditorStateChange={this.handleChange.bind(this, i)}
                        />
                    </Grid>


                </Grid>
            </div>
            )
        } )
    }
    handleSubmit = () => {

        Swal.fire({
            title:
                "Are you sure you want to submit exam "
            ,
            text:
                "You won't be able to revert this this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Create",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isLoading: true,
                    mode: 'submit'
                });
                this.doSubmit();
            }
        });
    };
    doSubmit = () =>{
        const {exam, questions, status} = this.state;
        if (status)
            return;
        http
            .post(endpoints.EXAM_THEORY_SUBMIT,{questions,exam: exam.id
                }
            )
            .then(res => {
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    icon: "success",
                    title: "Exam submitted "
                });
                this.setState({
                    isLoading: false,
                    status: true,
                });
                // setTimeout(()=>{
                //     this.props.history.push('theory-subjects',{
                //         course, level
                //
                //     });
                // }, 2000);
            })
            .catch(error => {
                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000
                });
                if (!this.state.status) {
                    Toast.fire({
                        icon: "error",
                        title: errorMessage
                    });
                }
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }

    render() {
        const {subject, isLoading, value, duration, questions, errorMessage, timerdate,timeLeft, exam, mode, status, instruction} = this.state;
        return (
            <MyLoader active={isLoading}>
                {mode === 'exam' ?
                    <div className="container">
                        <Grid container spacing={3}>
                            <Grid item xs={8}>
                                {subject &&
                                <div className="col-6">
                                    <h2>{subject.title} - Theory Exam </h2>
                                    <p><strong>Instruction: {instruction}</strong></p>
                                    <p>Duration: {duration} minutes</p>

                                </div>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <div className=" text-right">
                                    <label className="mr-1">Time:</label>
                                    {exam &&
                                    <Timer
                                        style={[]}
                                        startTime={new Date()}
                                        duration={timeLeft * 60}
                                        onEnd={() => {
                                            this.setState({mode: 'submit'});
                                            this.doSubmit();
                                            // this.props.onSubmit("submit");
                                        }}
                                        intervalText={(date, hour, min, sec) =>
                                            `${min}:${sec} left`
                                        }
                                    />
                                    }
                                    {/*<select onChange={this.onChange}>*/}
                                    {/*    {this.state.quizes.map(q => <option key={q.id} value={q.id}>{q.name}</option>)}*/}
                                    {/*</select>*/}
                                </div>
                            </Grid>


                        </Grid>
                        <div>


                        </div>


                        <div className="Sample mTop">

                            {errorMessage ?
                                <h1>{errorMessage}</h1>
                                : <div>
                                    <header>
                                        <h2>Exam Session</h2>
                                    </header>
                                    {this.createUI()}
                                </div>
                            }


                            {/*<input type='button' value='add more' onClick={this.addClick.bind(this)}/>*/}
                            {questions.length > 0 &&
                            <Button variant="success" onClick={() => this.handleSubmit()}
                                    style={{marginTop: 15}}>Submit</Button>

                            }
                        </div>

                    </div>

                    :
                    <div className='container'>
                        {subject &&
                        <h1> You have completed your {subject.code} Exam</h1>
                        }
                        {!status &&
                        <div>
                            <p>But your exam has not been submitted, ensure to submit before leaving this page, click below to submit</p>
                            <Button variant="success" style={{marginBottom:20}}     disabled={this.state.isLoading} onClick={() => this.doSubmit()}>Submit</Button>
                        </div>
                        }
                        <Link to={{
                            pathname: '/take-theory-exam',
                        }}
                              className=""><Button variant="primary" >Go back</Button>
                        </Link>
                    </div>
                }
            </MyLoader>
        );
    }
}

export default TheoryExamSession;
