import {actionTypes} from './actionTypes';
import { http, endpoints } from "../../../config/http";
import { put, takeLatest } from "redux-saga/effects";

export const actions = {
    getExams: () => ({
        type: actionTypes.GetExams,
    }),
    fulfillExam: data => ({ type: actionTypes.QuizLoad, payload: { data } }),
    failedToLoadExamSession: (message) =>({
        type: actionTypes.ExamFailure, payload: {message}
    }),
    // onUpdateTimerA: payload => dispatch({ type: actionTypes.AStartTimeUpdate, payload }),
    // onUpdateTimerB: payload => dispatch({ type: actionTypes.BStartTimeUpdate, payload }),
    // onUpdateTimerC: payload => dispatch({ type: actionTypes.CStartTimeUpdate, payload }),
    // SetScore: payload => dispatch({ type: actionTypes.SetScore, payload }),

};
const config = {
    'allowBack': true,
    'allowReview': true,
    'autoMove': false,  // if true, it will move to next question automatically when answered.
    'duration': 5,  // indicates the time in which quiz needs to be completed. 0 means unlimited.
    'pageSize': 1,
    'requiredAll': false,  // indicates if you must answer all the questions before submitting.
    'richText': false,
    'shuffleQuestions': false,
    'shuffleOptions': false,
    'showClock': true,
    'showPager': true,
    'theme': 'none'
};

export function* saga() {
    yield takeLatest(actionTypes.GetExams, function* getExamSaga(action) {
        try {
            const result= yield http
                .get(endpoints.GET_QUESTIONS);
            if (result.status){
                var response = result.data;
                response.config = config;
                console.log(response);
                yield put(actions.fulfillExam(response));
            }

        }
        catch (e) {
            console.log("error" +e);
            yield put(actions.failedToLoadExamSession(e? e:null));
        }


    });


}