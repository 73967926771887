import React, {Component} from 'react';
import {Table, Button, Spinner, Modal, Alert} from "react-bootstrap";
import {http, endpoints} from '../../../config/http';
import {Formik} from "formik";
import {TextField} from "@material-ui/core";
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import clsx from "clsx";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Swal from "sweetalert2";
import store, {persistor} from "../../../store/store";

const useStyles = makeStyles(theme => ({
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

class Students extends Component {
    constructor() {
        super();
        this.state = {
            isLoading: false,
            students: [],
            show:false,
            isSubmitting: false,
            paddingRight: "2.5rem",
            open: false,
            cat: "",
            status: null,
            success:false,
            currentStudent: null,
            showSectionC:false,
            showSectionB:false,
            sectionCscore: null,
            sectionBscore: null,
            isSubmittingScore: false,
            isDeleting: false,
            course:null,
            level: null,
            showView: false,
            currentItem: null,
        };
        this.handleShowView = this.handleShowView.bind(this);
        this.handleCloseView = this.handleCloseView.bind(this);


    }

    handleResetSession = (id) => {
        const { course, level } = this.props.location.state;

        Swal.fire({
            title:
                "Are you sure you want to reset exam session "
            ,
            text:
                "Student will be able to take this exam again",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Reset",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isDeleting: true
                });
                http
                    .put(endpoints.SESSION_RESET.replace(":id",id)
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "Exam reset successfully "
                        });
                        this.setState({
                            isDeleting: false,
                            currentItem: res.data
                        });

                        this.loadStudents(course.id, level);
                    })
                    .catch(error => {
                        this.setState({
                            isDeleting: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };

    handleDelete = (id) => {
        Swal.fire({
            title:
                "Are you sure you want to delete student "
            ,
            text:
                "You won't be able to revert this this",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Delete",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isDeleting: true
                });
                http
                    .delete(endpoints.DELETE_USER.replace(":id",id)
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "User deleted successfully "
                        });
                        this.setState({
                            isDeleting: false
                        });
                        this.loadStudents();

                    })
                    .catch(error => {
                        this.setState({
                            isDeleting: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };

    handleShowView(item) {
        this.setState({ showView: true, currentItem: item  });
    }
    handleCloseView() {
        this.setState({ showView: false, currentItem: null });
    }

    handleSubmitSecondScore(event){
        event.preventDefault();
        this.setState({
            isSubmittingScore :false,
        })
        http
            .put(endpoints.UPDATE_SECOND_SCORE,
                {score: this.state.sectionBscore,  user_id: this.state.currentStudent.id}
            )
            .then(res => {
                this.setState({
                    isSubmittingScore :false,
                })
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    icon: "success",
                    title: "Score updated"
                });
                this.loadStudents();
            })
            .catch(error => {

                this.setState({
                    isSubmittingScore :false,
                })
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000
                });

                Toast.fire({
                    icon: "error",
                    title: errorMessage
                });
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }

    componentDidMount(){

       this.setupCourse();

    }
    setupCourse(){
        const { course, level } = this.props.location.state;
        this.setState({
            course, level
        });
        this.loadStudents(course.id, level);
    }


    enableLoadling() {
        this.setState({ isSubmitting: true,paddingRight: "3.5rem",success: false });
    }
    disableLoadling() {
        this.setState({ isSubmitting: false,paddingRight: "2.5rem" });
    }





    loadStudents(dept, level){
        this.setState({
            isLoading: true
        });
        http
            .get(endpoints.GET_STUDENTS.replace(':dept', dept).replace(':level', level)
            )
            .then(res => {
                console.log(res);
                this.setState({
                    isLoading :false,
                    students: res.data
                })
            })
            .catch(error => {

                this.setState({
                    isLoading: false
                });
                let errorMessage =
                    (typeof error === "string" && error) ||
                    "An error occured, please try again in a moment.";
                // alert(JSON.stringify(error));
                console.log(errorMessage);
            });
    }
    handleChange(event) {
        const {name, value} = event.target;
        this.setState({
            [name]: value
        });
    }


    handleEnable = (item) => {
        const {course, level} = this.state;
        Swal.fire({
            title:
                item.exam? "Are you sure you want to disable "+item.name:"Are you sure you want to enable "+item.name,

            text:
            item.exam?"He/she will no longer have access to take exam":"He/she will have full access to take exam" ,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: item.exam? "Disable": "Enable",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isDeleting: true
                });
                http
                    .put(endpoints.ENABLE_STUDENT.replace(":id",item.id)
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "User UPDATED successfully "
                        });
                        this.setState({
                            isDeleting: false
                        });
                        this.loadStudents(course.id, level);

                    })
                    .catch(error => {
                        this.setState({
                            isDeleting: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };

    handleReset= (item) => {
        const {course, level} = this.state;
        Swal.fire({
            title:
                "Are you sure you reset password for "+item.name,

            text:
                "Password will be reset to 'password'" ,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: "Reset",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33"
        }).then(result => {
            if (result.value) {
                this.setState({
                    isDeleting: true
                });
                http
                    .put(endpoints.PASSWORD_RESET.replace(":id",item.id)
                    )
                    .then(res => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "success",
                            title: "Password reset successfully "
                        });
                        this.setState({
                            isDeleting: false
                        });

                    })
                    .catch(error => {
                        this.setState({
                            isDeleting: false
                        });
                        let errorMessage =
                            (typeof error === "string" && error) ||
                            "An error occured, please try again in a moment.";

                        const Toast = Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 3000
                        });

                        Toast.fire({
                            icon: "error",
                            title: errorMessage
                        });
                        // alert(JSON.stringify(error));
                        console.log(errorMessage);
                    });
            }
        });
    };
    getRowsData = function() {
        const {students} = this.state;
        return students.map((item, index) => {
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{item.matric}</td>

                    <td>{item.name} </td>

                    <td><Button variant={item.exam ?'danger': 'success'}  disabled={this.state.isDeleting} onClick={()=> this.handleEnable(item)}>{item.exam ?'Disable': 'Enable'}</Button>
                        <Button variant="primary"  disabled={this.state.isDeleting} onClick={() => this.handleShowView(item)}>Check Result</Button>
                        <Link to={{
                            pathname: '/results',
                            state: {
                                student: item,
                            }
                        }}
                              className=""><Button variant="primary" style={{marginLeft:10, marginRight:10}}>Theory Result</Button>
                        </Link>


                        <Button variant="primary"  disabled={this.state.isDeleting} onClick={()=> this.handleReset(item)}>Reset password</Button>
                    </td>
                </tr>
            );
        });
    };

    getRowsCurrentData = function() {
        const {currentItem} = this.state;
        return currentItem.sessions.map((item, index) => {
            return (
                <tr key={index} >
                    <td>{index + 1}</td>
                    <td>{item.subject.code} - {item.subject.title}</td>
                    <td>{item.score !== null ?item.score : 'Not taken yet'}</td>
                    <td>
                    <Button variant="primary"  disabled={false} onClick={()=> this.handleResetSession(item.id)}>Reset</Button>
                    </td>
                </tr>
            );
        });
    };


    render() {
        const {isLoading,course, level, currentItem, showView} = this.state;
        return (
            <div className="container">
                <div>
                    {course &&
                    <div>
                        <h2>{course.name} - {level}00 level students</h2>

                    </div>
                    }
                </div>
                {isLoading &&
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
                }

                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Matric</th>
                        <th>Name</th>

                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.getRowsData()}
                    </tbody>
                </Table>

                {currentItem &&

                <Modal show={currentItem && showView} onHide={this.handleCloseView} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>Exams for {currentItem.name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>


                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>Course</th>
                                <th>Score</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.getRowsCurrentData()}
                            </tbody>
                        </Table>


                    </Modal.Body>

                </Modal>
                }
            </div>
        );
    }
}

export default Students;
